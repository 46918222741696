import { createTheme } from '@mui/material/styles'
import { esES } from '@mui/x-data-grid'
const theme = createTheme(
  {
    typography: {
      fontFamily: ['system-ui', 'Open Sans'].join(','),
    },
    status: {
      danger: '#e53e3e',
    },
    palette: {
      primary: {
        main: '#0971f1',
        darker: '#053e85',
      },
      neutral: {
        main: '#802f86',
        contrastText: '#fff',
      },
    },
  },
  esES,
)

export default theme;
